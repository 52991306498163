let en = {
    SEOR: "What is SEOR?",
    content1: "SEOR-Network's goal is to effectively reduce the difficulty of technology development when Internet developers apply to blockchain technology, and when technicians in major public chain ecosystems cross-chain to the research and development of other public chain technologies.",
    content6: "Also, SEOR-Network builds a common middle layer between decentralized applications and various blockchains to help them interact efficiently and create a complete Web3.0 infrastructure.",
    problems: "SEOR addresses these problems:",
    content2: "Single System Support",
    content3: "Lack of innovation",
    content4: "Single Use Case Operation",
    content5: "Fragmented Access",
    MultiChain: "Multi-chain Integration",
    MultiChainContent: "SEOR is empowered by Polkadot and perform as the serving middleware to support both Layer 1 and Layer 2 protocols (on-chain data and off-chain data).",
    Flexible: "Flexible Customization",
    FlexibleContent: "SEOR supports flexible data network customization by using the self-innovated solution lightweight Oracle network (“LON”), which can also integrate with the standard Oracle protocol.",
    Multiple: "Multiple Modes",
    MultipleContent: "SEOR supports three operating modes - request/response, subscription/broadcast,immediate reading.",
    Compatibility: "Compatibility Support",
    CompatibilityContent: "SEOR supports multi-chain/cross-chain DAPPs’ off-chain data requirements and support.",
}

let zh = {
    SEOR: "什么是SEOR?",
    content1: "Bring more use-cases to blockchains beyond DeFi:SEOR的灵活Oracle设计使开发人员可以使用任何数据，包括真实新闻，娱乐，体育，天气，随机数（Game、博彩）等。开发人员可以使用多种技术来创建自定义Oracle数据服务，并在几分钟内将智能合约与传统Web API连接起来",
    problems: "SEOR 解决了这些问题：",
    content2: "单一系统支持",
    content3: "缺乏创新",
    content4: "单一用例操作",
    content5: "碎片化访问",
    MultiChain: "多链整合",
    MultiChainContent: "SEOR 由 Polkadot 授权并作为服务中间件执行，以支持第 1 层和第 2 层协议（链上数据和链下数据）。",
    Flexible: "灵活定制",
    FlexibleContent: "SEOR通过使用自主创新的解决方案轻量级Oracle网络（“LON”），支持灵活的数据网络定制，也可以与标准的Oracle协议集成。",
    Multiple: "多种模式",
    MultipleContent: "SEOR 支持三种操作模式——请求/响应、订阅/广播、即读。",
    Compatibility: "兼容性支持",
    CompatibilityContent: "SEOR 支持多链/跨链 DAPP 的链下数据需求和支持。",
}

let name = 'Seor'

export {
    name, en, zh
}