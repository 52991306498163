<template>
  <div class="framework-box" id="framework">
    <div class="framework">
      <div class="framework-title">
        {{ $t("Home.Framework.TechnicalFramework") }}
      </div>
      <div class="to-technology" @click="toTechnology()">
        <span>
          {{ $t("Home.Framework.SEORTechnology") }}
        </span>
        <img src="@/assets/home/tolink.png" alt="" />
      </div>
      <div class="framework-tab">
        <div class="left">
          <div
            class="info"
            :class="selectedIndex == 1 ? 'selected' : ''"
            @click="onTab(1)"
          >
            <div class="title">
              {{ $t("Home.Framework.Layered") }}
            </div>
            <div class="content">
              {{ $t("Home.Framework.LayeredContent") }}
            </div>
          </div>
          <div
            class="info"
            :class="selectedIndex == 2 ? 'selected' : ''"
            @click="onTab(2)"
          >
            <div class="title">
              {{ $t("Home.Framework.Lightweight") }}
            </div>
            <div class="content">
              {{ $t("Home.Framework.LightweightContent") }}
            </div>
          </div>
          <div
            class="info"
            :class="selectedIndex == 3 ? 'selected' : ''"
            @click="onTab(3)"
          >
            <div class="title">
              {{ $t("Home.Framework.Micro") }}
            </div>
            <div class="content">
              {{ $t("Home.Framework.MicroContent") }}
            </div>
          </div>
          <div
            class="info"
            :class="selectedIndex == 4 ? 'selected' : ''"
            @click="onTab(4)"
          >
            <div class="title">
              {{ $t("Home.Framework.Governance") }}
            </div>
            <div class="content">
              {{ $t("Home.Framework.GovernanceContent") }}
            </div>
          </div>
        </div>
        <div class="left-mobile">
          <div
            class="info"
            :class="selectedIndex == 1 ? 'selected' : ''"
            @click="onTab(1)"
          >
            <div class="title">
              {{ $t("Home.Framework.Layered") }}
            </div>
          </div>
          <div
            class="info"
            :class="selectedIndex == 2 ? 'selected' : ''"
            @click="onTab(2)"
          >
            <div class="title">
              {{ $t("Home.Framework.Lightweight") }}
            </div>
          </div>
          <div
            class="info"
            :class="selectedIndex == 3 ? 'selected' : ''"
            @click="onTab(3)"
          >
            <div class="title">
              {{ $t("Home.Framework.Micro") }}
            </div>
          </div>
          <div
            class="info"
            :class="selectedIndex == 4 ? 'selected' : ''"
            @click="onTab(4)"
          >
            <div class="title">
              {{ $t("Home.Framework.Governance") }}
            </div>
          </div>
        </div>
        <div class="right wow slideInUp fade-in">
          <div class="one" v-show="selectedIndex == 1">
            <img
              src="@/assets/home/frame/Layered_Modular_Microservice_Architecture@2x.png"
              alt=""
            />
          </div>
          <div class="one" v-show="selectedIndex == 2">
            <img src="@/assets/home/frame/Short-chain@2x.png" alt="" />
          </div>
          <div class="one" v-show="selectedIndex == 3">
            <img
              src="@/assets/home/frame/Lightweight_Oracle_Network@2x.png"
              alt=""
            />
          </div>
          <div class="one" v-show="selectedIndex == 4">
            <img
              src="@/assets/home/frame/Cross_chain_Governance@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: 1,
    };
  },
  methods: {
    onTab(n) {
      this.selectedIndex = n;
    },
    toTechnology() {
      this.$router.push({ name: "technology" });
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-in {
  animation: fade-in 1s ease-in-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    right: 0px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}
.framework-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 144px;
}
.framework {
  &-title {
    font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 33px;
    color: #000000;
  }
  .to-technology {
    height: 20px;
    margin-top: 16px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    background-image: -webkit-linear-gradient(
      right,
      rgba(26, 97, 234, 1) 80%,
      rgba(26, 192, 234, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -moz-linear-gradient(
      right,
      rgba(26, 97, 234, 1) 80%,
      rgba(26, 192, 234, 1) 100%
    );
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    background-image: -o-linear-gradient(
      right,
      rgba(26, 97, 234, 1) 80%,
      rgba(26, 192, 234, 1) 100%
    );
    -o-background-clip: text;
    -o-text-fill-color: transparent;
    img {
      width: 7.5px;
      height: 7px;
      margin-left: 4px;
    }
    span:active {
      border-bottom: 1px solid rgba(26, 97, 234, 1);
    }
  }
  .to-technology>span:hover {
    border-bottom: 1px solid #1ac0ea;
  }
  .to-technology>span:active {
    border-bottom: 1px solid rgba(26, 97, 234, 1);
  }
  &-tab {
    margin-top: 56px;
    display: flex;
    .left-mobile {
      display: none;
    }
    .left {
      display: block;
      width: 312px;
      .info {
        padding-left: 28px;
        margin-bottom: 32px;
        cursor: pointer;
      }
      .title {
        width: 284px;
        font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
          sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.25);
      }
      .content {
        margin-top: 8px;
        width: 284px;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.25);
      }
      .selected {
        border-left: 4px solid #1a61ea;
        padding-left: 24px !important;
        .title {
          color: #000000 !important;
        }
        .content {
          color: #000000 !important;
        }
      }
    }
    .right {
      width: 688px;
      height: 416px;
      margin: 0 32px 0 80px;
      .one {
        width: 100%;
        height: 100%;
        position: relative;
        animation: fade-in 1s ease-in-out both;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 780px) {
  .framework-box {
    padding-bottom: 88px;
  }
  .framework {
    width: 100%;
    &-title {
      padding: 0 32px;
      font-size: 28px;
      line-height: 32px;
    }
    .to-technology {
      padding: 0 32px;
    }
    .right {
      padding: 0 32px;
      box-sizing: border-box;
    }
    &-tab {
      margin-top: 44px;
      height: 100%;
      flex-flow: column;
      width: 100%;
      .left {
        display: none;
      }
      .left-mobile {
        display: flex;
        overflow-x: auto;
        width: 100%;
        box-sizing: border-box;
        padding-left: 32px;
        .info {
          cursor: pointer;
          margin-right: 16px;
          padding-top: 12px;
          height: 75px;
        }
        .title {
          width: 148px;
          font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
            sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.25);
        }
      }
      .left-mobile::-webkit-scrollbar {
        display: none;
      }
      .right {
        width: 100%;
        margin-left: 0;
        height: 100%;
        .one {
          margin-top: 32px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .selected {
    border-left: 0;
    padding-left: 0 !important;
    border-top: 4px solid #1a61ea;
    padding-top: 8px !important;
    .title {
      color: #000000 !important;
    }
  }
}
@media screen and (min-width: 780px) and (max-width: 1140px) {
  .framework {
  }
  .framework-box {
    justify-content: inherit;
    padding-left: 32px;
  }
  .framework-tab {
    margin-top: 44px;
    height: 100%;
    flex-flow: column;
    width: 100%;
    .left {
      display: none;
    }
    .left-mobile {
      display: flex;
      overflow-x: auto;
      width: 100%;
      box-sizing: border-box;
      .info {
        cursor: pointer;
        margin-right: 16px;
        padding-top: 12px;
        height: 75px;
      }
      .title {
        width: 148px;
        font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
          sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .left-mobile::-webkit-scrollbar {
      display: none;
    }
    .right {
      width: 100%;
      margin-left: 0;
      height: 100%;
      .one {
        margin-top: 32px;
        img {
          width: 688px;
          height: auto;
        }
      }
    }
  }
  .selected {
    border-left: 0;
    padding-left: 0 !important;
    border-top: 4px solid #1a61ea;
    padding-top: 8px !important;
    .title {
      color: #000000 !important;
    }
  }
}
</style>