let en = {
    Home: "Home",
    Technology: "Technology",
    Token: "Token",
    Documentation:"Docs",
    Whitepaper: "Whitepaper",
    Introduction:"Onepage",
    Deck:"PitchDeck",
}

let zh = {
    Home: "首页",
    Technology: "技术",
    Token: "Token",
    Whitepaper: "白皮书",
    Introduction:"Introduction",
    Deck:"Deck",
    Documentation:"Documentation",
}

let name = 'Nav'

export {
    name, en, zh
}