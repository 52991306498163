<template>
  <div class="footer-menu">
    <footer class="footer">
      <div class="footer-left">
        <div>
          <div class="nav-img">
            <img src="@/assets/footer/logo-seor.png" alt="" />
          </div>
          <div class="seor-info">
            <div class="discord">
              <a href="https://discord.gg/czPzydhSZf"></a>
            </div>
            <div class="telegram">
              <a href="https://t.me/SeorGroup"></a>
            </div>
            <div class="twitter">
              <a href="https://twitter.com/SEOR001"></a>
            </div>
            <div class="medium">
              <a href="https://medium.com/@SEOR"></a>
            </div>
            <div class="youtube">
              <a href="https://www.youtube.com/c/SEORNetwork"></a>
            </div>
            <div class="envelope">
              <a href="contact@seor.io"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <ul>
          <li>
            {{ $t("Footer.Footer.Home") }}
          </li>
          <li @click="toHome(1)">
            <span>{{ $t("Footer.Footer.SEOR") }}</span>
          </li>
          <li @click="toHome(2)">
            <span>{{ $t("Footer.Footer.Technical") }}</span>
          </li>
          <li @click="toHome(3)">
            <span>{{ $t("Footer.Footer.Application") }}</span>
          </li>
          <li @click="toHome(4)">
            <span>{{ $t("Footer.Footer.Collaborative") }}</span>
          </li>
          <li @click="toHome(5)">
            <span>{{ $t("Footer.Footer.Roadmap") }}</span>
          </li>
        </ul>
        <ul>
          <li>
            {{ $t("Footer.Footer.Technology") }}
          </li>
          <li @click="toTechnology(1)">
            <span>{{ $t("Footer.Footer.Layer") }}</span>
          </li>
          <li @click="toTechnology(2)">
            <span>{{ $t("Footer.Footer.Mainnet") }}</span>
          </li>
          <li @click="toTechnology(3)">
            <span>{{ $t("Footer.Footer.Consensus") }}</span>
          </li>
          <li @click="toTechnology(4)">
            <span>{{ $t("Footer.Footer.TokenUtility") }}</span>
          </li>
        </ul>
        <ul>
          <li>
            {{ $t("Footer.Footer.Token") }}
          </li>
          <li @click="toToken()">
            <span>{{ $t("Footer.Footer.TheSeorToken") }}</span>
          </li>
          <ul>
            <li>Ecosystem</li>
            <li>
              <span>
                <a target="_blank" href="https://sea.seor.io/">SEORSEA</a>
              </span>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            {{ $t("Footer.Footer.Docs") }}
          </li>
          <li>
            <span>
              <a
                target="_blank"
                href="https://contact-97.gitbook.io/seor-network/"
                >Overview</a
              >
            </span>
          </li>
          <li>
            <span>
              <a
                target="_blank"
                href="https://docsend.com/view/j7amdrwc353n957a"
                >{{ $t("Footer.Footer.Whitepaper") }}</a
              >
            </span>
          </li>
          <li>
            <span>
              <a
                target="_blank"
                href="https://docsend.com/view/p7v9yng9jkjf4mzd"
              >
                {{ $t("Footer.Footer.Introduction") }}
              </a>
            </span>
          </li>
          <li>
            <span
              ><a
                target="_blank"
                href="https://docsend.com/view/emchpkyswwvssfdw"
                >{{ $t("Footer.Footer.Deck") }}</a
              ></span
            >
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {
    async toHome(index) {
      this.$router.push({ name: "home" });
      await this.$nextTick(() => {
        let seorHeight = document.getElementById("seor").scrollHeight;

        let heroHeight = document.getElementById("hero").scrollHeight;
        let applicationHeight =
          document.getElementById("application").scrollHeight;
        let partnersHeight = document.getElementById("partners").scrollHeight;
        let frameworkHeight = document.getElementById("framework").scrollHeight;

        if (index == 0) {
          document.documentElement.scrollTop = 0;
        } else if (index == 1) {
          document.documentElement.scrollTop = heroHeight;
          console.log(1111111);
        } else if (index == 2) {
          document.documentElement.scrollTop = heroHeight + seorHeight;
        } else if (index == 3) {
          document.documentElement.scrollTop =
            heroHeight + seorHeight + frameworkHeight;
        } else if (index == 4) {
          document.documentElement.scrollTop =
            heroHeight + seorHeight + frameworkHeight + applicationHeight;
        } else {
          document.documentElement.scrollTop =
            heroHeight +
            seorHeight +
            frameworkHeight +
            applicationHeight +
            partnersHeight;
        }
      });
    },
    async toTechnology(index) {
      this.$router.push({ name: "technology" });
      await this.$nextTick(() => {
        let browser = {
          versions: (function () {
            var u = navigator.userAgent,
              app = navigator.appVersion;

            return {
              //移动终端浏览器版本信息

              trident: u.indexOf("Trident") > -1, //IE内核

              presto: u.indexOf("Presto") > -1, //opera内核

              webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核

              gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核

              mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端

              ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端

              android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器

              iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器

              iPad: u.indexOf("iPad") > -1, //是否iPad

              webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
            };
          })(),
          language: (
            navigator.browserLanguage || navigator.language
          ).toLowerCase(),
        };
        //如果是移动端就进行这里
        if (
          browser.versions.mobile ||
          browser.versions.ios ||
          browser.versions.android ||
          browser.versions.iPhone ||
          browser.versions.iPad
        ) {
          let layerHeight = document.getElementById("layerMobile").scrollHeight;
          let mainnetHeight =
            document.getElementById("mainnetMobile").scrollHeight;
          let consensusHeight =
            document.getElementById("consensusMobile").scrollHeight;
          if (index == 0) {
            document.documentElement.scrollTop = 0;
          } else if (index == 1) {
            document.documentElement.scrollTop = 0;
          } else if (index == 2) {
            document.documentElement.scrollTop = layerHeight;
          } else if (index == 3) {
            document.documentElement.scrollTop = layerHeight + mainnetHeight;
          } else {
            document.documentElement.scrollTop =
              layerHeight + mainnetHeight + consensusHeight;
          }
        } else {
          let layerHeight = document.getElementById("layer").scrollHeight;
          let mainnetHeight = document.getElementById("mainnet").scrollHeight;
          let consensusHeight =
            document.getElementById("consensus").scrollHeight;
          if (index == 0) {
            document.documentElement.scrollTop = 0;
          } else if (index == 1) {
            document.documentElement.scrollTop = 0;
          } else if (index == 2) {
            document.documentElement.scrollTop = layerHeight;
          } else if (index == 3) {
            document.documentElement.scrollTop = layerHeight + mainnetHeight;
          } else {
            document.documentElement.scrollTop =
              layerHeight + mainnetHeight + consensusHeight;
          }
        }
      });
    },
    async toToken() {
      this.$router.push({ name: "token" });
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-menu {
  width: 100%;
  height: 268px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.footer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0 172px;
  box-sizing: border-box;
  &-right {
    flex-basis: 52%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      color: rgba(0, 0, 0, 0.6);
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 13px;
      height: 136px;
      line-height: 16px;
    }
    li {
      list-style: none;
      margin-bottom: 12px;
    }
    li:first-child {
      font-weight: bold;
      color: #000000;
    }
    li > span {
      cursor: pointer;
    }
    li > span > a {
      color: rgba(0, 0, 0, 0.6);
    }
    li > span > a:active {
      color: rgba(0, 0, 0, 1);
    }
    li > span:hover {
      text-decoration-line: underline;
      color: rgba(0, 0, 0, 0.6);
    }
    li > span:active {
      color: rgba(0, 0, 0, 1);
    }
  }
  &-left {
    flex-basis: 42%;
    display: flex;
    .seor-info {
      display: flex;
      margin-top: 26px;
      div {
        margin-right: 24px;
      }
      div > a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
      .discord {
        background: url("../../assets/footer/discord.svg") no-repeat center
          center;
        background-size: 100% auto;
        width: 15px;
        height: 20px;
      }
      .discord:hover {
        background: url("../../assets/footer/discord_hover.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .discord:active {
        background: url("../../assets/footer/discord_active.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .telegram {
        background: url("../../assets/footer/telegram.svg") no-repeat center
          center;
        background-size: 100% auto;
        width: 17px;
        height: 20px;
      }
      .telegram:hover {
        background: url("../../assets/footer/telegram_hover.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .telegram:active {
        background: url("../../assets/footer/telegram_active.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .twitter {
        background: url("../../assets/footer/twitter.svg") no-repeat center
          center;
        background-size: 100% auto;
        width: 17px;
        height: 20px;
      }
      .twitter:hover {
        background: url("../../assets/footer/twitter_hover.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .twitter:active {
        background: url("../../assets/footer/twitter_active.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .medium {
        background: url("../../assets/footer/medium.svg") no-repeat center
          center;
        background-size: 100% auto;
        width: 15px;
        height: 20px;
      }
      .medium:hover {
        background: url("../../assets/footer/medium_hover.svg") no-repeat center
          center;
        background-size: 100% auto;
      }
      .medium:active {
        background: url("../../assets/footer/medium_active.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .youtube {
        background: url("../../assets/footer/youtube.svg") no-repeat center
          center;
        background-size: 100% auto;
        width: 20px;
        height: 20px;
      }
      .youtube:hover {
        background: url("../../assets/footer/youtube_hover.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .youtube:active {
        background: url("../../assets/footer/youtube_active.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .envelope {
        background: url("../../assets/footer/envelope.svg") no-repeat center
          center;
        background-size: 100% auto;
        width: 17px;
        height: 20px;
      }
      .envelope:hover {
        background: url("../../assets/footer/envelope_hover.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
      .envelope:active {
        background: url("../../assets/footer/envelope_active.svg") no-repeat
          center center;
        background-size: 100% auto;
      }
    }
    .nav-img {
      margin-top: 69px;
      img {
        vertical-align: bottom;
        width: 129px;
        height: auto;
      }
      .title {
        font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
          sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        display: inline-block;
        height: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.08em;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
}
@media screen and (min-width: 500px) and (max-width: 750px) {
  .footer-menu {
    height: 240px;
  }
  .footer {
    display: inline-block;
    padding: 0;
    &-left {
      .nav-img {
        margin-top: 30px;
      }
      justify-content: initial;
      padding-left: 32px;
      .seor-info {
        margin-top: 26px;
      }
    }
    &-right {
      display: block;
      padding-left: 32px;
      margin-top: 48px;
      padding-bottom: 32px;
      ul {
        margin-top: 32px;
        height: auto;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .footer-menu {
    height: 240px;
  }
  .footer {
    display: inline-block;
    padding-left: 32px;
    &-left {
      .nav-img {
        margin-top: 30px;
      }
      justify-content: initial;
      padding-left: 32px;
      .seor-info {
        margin-top: 26px;
      }
    }
    &-right {
      display: block;
      padding-left: 32px;
      margin-top: 48px;
      padding-bottom: 32px;
      ul {
        margin-top: 32px;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 750px) and (max-width: 1220px) {
  .footer {
    padding: 0 50px;
    &-left {
      flex-basis: 32%;
    }
    &-right {
      flex-basis: 60%;
    }
  }
}
</style>