import Home from './home'
import Nav from './nav'
import Footer from './footer'
import Technology from './technology'
import Token from './token'

export default {
  Home: Home.i18n.en,
  Nav: Nav.i18n.en,
  Footer:Footer.i18n.en,
  Technology:Technology.i18n.en,
  Token:Token.i18n.en,
}