let en = {
    Home: "Home",
    SEOR: "What is SEOR",
    Technical: "Technical Framework",
    Application: "Application Scenarios",
    Collaborative: "Collaborative Partners",
    Roadmap: "Roadmap",
    Technology: "Technology",
    Layer: "The Middle Layer",
    Mainnet: "Mainnet",
    Consensus: "Consensus",
    TokenUtility: "Token Utility",
    Docs: "Docs",
    Whitepaper: "Whitepaper",
    content: "SEOR offers a customizable low-code development Oracle data platform for Link Reality, with high scalability and low technical threshold characteristics.",
    Token: "Token",
    TheSeorToken: "The SEOR Token",
    Introduction: "Onepage",
    Deck: "PitchDeck",
}

let zh = {
    Home: "首页",
    SEOR: "什么是SEOR",
    Technical: "技术框架",
    Application: "Application Scenarios",
    Collaborative: "合作伙伴",
    Roadmap: "路线图",
    Technology: "技术",
    Layer: "中间层",
    Mainnet: "主网",
    Consensus: "共识",
    TokenUtility: "代币用途",
    Docs: "文档",
    Whitepaper: "白皮书",
    content: "SEOR为链路现实提供了一个可定制的低代码开发Oracle数据平台，具有高可扩展性和低技术阈值的特点。",
    Token: "Token",
    TheSeorToken: "The Seor Token",
    Introduction: "Introduction",
    Deck: "Deck",
}

let name = 'Footer'

export {
    name, en, zh
}