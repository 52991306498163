<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  beforeCreate() {
    let count = 0;
    let imgs = [
      require("@/assets/home/image-banner-1.png"),
      require("@/assets/home/image-banner-2.png"),
      require("@/assets/home/image-banner-3.png"),
      require("@/assets/home/image-banner-4.png"),
    ];
    for (let img of imgs) {
      let image = new Image();
      image.src = img;
      image.onload = () => {
        count++;
      };
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
::-webkit-scrollbar-thumb {
  width: 0;
  height: 0;
  color: transparent;
}
a {
  text-decoration: none;
}
</style>
