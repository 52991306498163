let en = {
    middleLayer:"The Middle Layer",
    content1:"SEOR-Network's goal is to effectively reduce the difficulty of technology development when Internet developers apply to blockchain technology, and when technicians in major public chain ecosystems cross-chain to the research and development of other public chain technologies.",
    content2:"Also, SEOR-Network builds a common middle layer between decentralized applications and various blockchains to help them interact efficiently and create a complete Web3.0 infrastructure.",
}

let zh = {
    middleLayer:"中间层",
    content1:"SEOR-Network 的目标是在互联网开发者应用区块链技术，以及各大公链生态系统的技术人员跨链到其他公链技术的研发时，有效降低技术开发的难度。",
    content2:"此外，SEOR-Network 在去中心化应用程序和各种区块链之间构建了一个通用的中间层，以帮助它们高效交互并创建完整的 Web3.0 基础设施。",
}

let name = 'Layer'

export {
    name, en, zh
}