let en = {
    Mainnet:"Mainnet",
    node:"Multiple Oracle nodes",
    mechanism:'"Submit-Expose" Mechanism',
    dataSource:"Multiple data sources or trusted single data sources",
    distribution:"Profit distribution",
    Consensus:"Consensus",
    Hybrid:"Hybrid Consensus Algorithm",
    Unlimited:"Unlimited Extension Capability",
    Safety:"Safety And Reliability",
    TokenUtility:"Token Utility",
    roles:"Three main roles in SEOR network:",
    mainnetNode:"Data verifier: Pledge SEOR to become a mainnet node",
    supplier:"Data provider: Pledge SEOR to become a data supplier",
    dataService:"Data user: Use SEOR to obtain data service",
}

let zh = {
    Mainnet:"主网",
    node:"多Oracle节点",
    mechanism:'“提交-揭露”机制',
    dataSource:"多数据源或可信单数据源",
    distribution:"利益分配",
    Consensus:"共识",
    Hybrid:"混合共识算法",
    Unlimited:"无限扩展能力",
    Safety:"安全可靠",
    TokenUtility:"代币用途",
    roles:"SEOR 网络中的三个主要角色：",
    mainnetNode:"数据验证者：承诺 SEOR 成为主网节点",
    supplier:"数据提供者：誓约SEOR成为数据提供者",
    dataService:"数据用户：使用 SEOR 获取数据服务",
}

let name = 'Introduce'

export {
    name, en, zh
}