import { i18nBuilder } from "../tools";

let i18n = i18nBuilder([
  require('../token/token'),
])

export default {
  i18n
}

