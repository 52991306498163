<template>
  <div class="application-box" id="application">
    <div class="application">
      <div class="application-title">
        {{ $t("Home.Application.Application") }}
      </div>
      <div class="application-content">
        <div class="left">
          <img src="@/assets/home/img-lcdp.png" alt="" />
        </div>
        <div class="right wow slideInUp fade-in">
          <div class="right-title">{{ $t("Home.Application.title1") }}</div>
          <div class="right-content">
            {{ $t("Home.Application.content1") }}
          </div>
        </div>
      </div>
      <div class="application-content">
        <div class="left">
          <img src="@/assets/home/img-lon.png" alt="" />
        </div>
        <div class="right wow slideInUp fade-in">
          <div class="right-title">{{ $t("Home.Application.title2") }}</div>
          <div class="right-content">
            {{ $t("Home.Application.content2") }}
          </div>
        </div>
      </div>
      <div class="application-content last-application">
        <div class="left">
          <img src="@/assets/home/img-crosschain.png" alt="" />
        </div>
        <div class="right wow slideInUp fade-in">
          <div class="right-title">{{ $t("Home.Application.title3") }}</div>
          <div class="right-content">
            {{ $t("Home.Application.content3") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {};
</script>
<style lang="scss" scoped>
.fade-in {
  animation: fade-in 1s ease-in-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.application-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 144px;
}
.application {
  &-title {
    font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 33px;
    color: #000000;
    margin-bottom: 48px;
  }
  &-content {
    background-color: rgba(0, 0, 0, 0.015);
    display: flex;
    margin-bottom: 64px;
    .left {
      img {
        width: 468px;
        height: auto;
        display: block;
        margin: 64px 0 0 48px;
      }
    }
    .right {
      margin: 64px 48px 88px;
      &-title {
        font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
          sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        height: 24px;
        width: 500px;
      }
      &-content {
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.6);
        width: 500px;
        margin-top: 16px;
      }
    }
  }
}
.last-application {
  margin-bottom: 0;
  .right {
    margin: 64px 48px 181px;
  }
}
@media screen and (max-width: 780px) {
  .application-box {
    padding: 0 0 88px;
  }
  .application {
    &-title {
      font-size: 28px;
      line-height: 32px;
      padding-left: 32px;
      box-sizing: border-box;
    }
    &-content {
      flex-direction: column;
      .left {
        padding: 32px 32px 0;
        box-sizing: border-box;
        img {
          width: 100%;
          margin: 0 auto;
        }
      }
      .right {
        margin: 0;
        &-title {
          width: 100%;
          margin-top: 24px;
          padding: 0 32px;
          box-sizing: border-box;
          font-size: 15px;
          line-height: 20px;
          height: auto;
        }
        &-content {
          width: 100%;
          font-size: 17px;
          line-height: 22px;
          padding: 0 32px 32px;
          box-sizing: border-box;
        }
      }
    }
  }
}
@media screen and (min-width: 780px) and (max-width: 1140px) {
  .application {
    padding: 0 32px;
    box-sizing: border-box;
  }
  .application-content {
    padding: 32px;
    box-sizing: border-box;
    .left {
      width: 50%;
      align-items: center;
      display: flex;
      img {
        width: 100%;
        height: auto;
        margin: 0;
      }
    }
    .right {
      width: 50%;
      margin: 0;
      .right-title {
        width: 100%;
      }
      .right-content {
        width: 100%;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}
</style>