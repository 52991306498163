<template>
  <div class="hero" id="hero">
    <div class="image-banner">
      <img src="@/assets/home/image-banner-1.png" alt="" class="banner1" v-show="banner1"/>
      <img src="@/assets/home/image-banner-2.png" alt="" class="banner2" v-show="banner2"/>
      <img src="@/assets/home/image-banner-3.png" alt="" class="banner3" v-show="banner3"/>
      <img src="@/assets/home/image-banner-4.png" alt="" class="banner4" v-show="banner4"/>
    </div>
    <div class="seal-oracle">
      {{ $t("Home.Hero.sealOracle") }}
    </div>
    <div class="offers">
      {{ $t("Home.Hero.offers") }}
    </div>
    <div class="to-whitepaper">
      <div class="a-whitepaper">
        <a
          target="_blank"
          href="https://static-files.seor.io/SEOR-Whitepaper.v3.0.pdf"
          >{{ $t("Home.Hero.readTheWhitepapper") }}</a
        >
        <img src="@/assets/home/tolink.png" alt="" />
      </div>
    </div>

    <div class="web3-logo">
      <img src="@/assets/home/logo_web3.png" alt="" />
    </div>
    <div class="project">
      {{ $t("Home.Hero.project") }}
    </div>
    <div class="arrow-down">
      <img src="@/assets/home/arrow_down.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner1: false,
      banner2: false,
      banner3: false,
      banner4: false,
    };
  },
  methods: {
    toWhitepaper() {
      this.$router.push({ name: "whitepaper" });
    },
  },
  mounted() {
    setTimeout(() => {
      this.banner1 = true;
    }, 100);
    setTimeout(() => {
      this.banner2 = true;
    }, 200);
    setTimeout(() => {
      this.banner3 = true;
    }, 500);
    setTimeout(() => {
      this.banner4 = true;
    }, 700);
  },
};
</script>

<style lang="scss" scoped>
.hero {
  padding-bottom: 66px;
  margin-bottom: 80px;
  .image-banner {
    width: 620px;
    height: 536px;
    margin: 0 auto;
    position: relative;
    img {
      width: 620px;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
    }
    .banner1 {
      z-index: 2;
      animation: rightmove 1.5s, ease-in-out;
      -webkit-animation: rightmove 1.5s, ease-in-out; /*Safari and Chrome*/
      animation-fill-mode: forwards;
    }
    .banner2 {
      z-index: 3;
      animation: rightmove 2.5s, ease-in-out;
      -webkit-animation: rightmove 2.5s, ease-in-out; /*Safari and Chrome*/
      animation-fill-mode: forwards;
    }
    .banner3 {
      z-index: 4;
      animation: rightmove 3s, ease-in-out;
      -webkit-animation: rightmove 3s, ease-in-out; /*Safari and Chrome*/
      animation-fill-mode: forwards;
    }
    .banner4 {
      z-index: 1;
      animation: rightmove 1.5s, ease-in-out;
      -webkit-animation: rightmove 1.5s, ease-in-out; /*Safari and Chrome*/
      animation-fill-mode: forwards;
    }
  }
  .seal-oracle {
    height: 42px;
    font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 44px;
    line-height: 42px;
    letter-spacing: 0.09em;
    color: #000000;
    margin-top: 24px;
    text-align: center;
  }
  .offers {
    width: 540px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin: 16px auto 0;
    box-sizing: border-box;
  }
  .to-whitepaper {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    img {
      width: 7.5px;
      height: 7px;
      margin-left: 4px;
    }
    .a-whitepaper {
      height: 20px;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      background: linear-gradient(117.24deg, #1ac0ea 12.56%, #1a61ea 65.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // background: -moz-linear-gradient(
      //   117.24deg,
      //   #1ac0ea 12.56%,
      //   #1a61ea 65.5%
      // );
      // -moz-background-clip: text;
      // -moz-text-fill-color: transparent;
      background: -o-linear-gradient(117.24deg, #1ac0ea 12.56%, #1a61ea 65.5%);
      -o-background-clip: text;
      -o-text-fill-color: transparent;
    }
    a:hover {
      border-bottom: 1px solid #1ac0ea;
    }
    a:active {
      border-bottom: 1px solid rgba(26, 97, 234, 1);
    }
  }

  .web3-logo {
    img {
      width: 73px;
      height: 40px;
      display: block;
      margin: 28px auto 4px;
    }
  }
  .project {
    width: 308px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 auto;
  }
  .arrow-down {
    img {
      width: 44px;
      height: auto;
      display: block;
      margin: 55px auto 4px;
    }
  }
}
@media screen and (max-width: 780px) {
  .hero {
    padding: 0 14px 48px;
    margin-bottom: 40px;
    .image-banner {
      width: 284px;
      height: 251px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .seal-oracle {
      font-size: 36px;
      line-height: 40px;
    }
    .offers {
      width: 100%;
      padding: 0 10px;
      font-size: 17px;
      line-height: 22px;
    }
    .project {
      width: 100%;
    }
  }
}
@keyframes rightmove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes rightmove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>