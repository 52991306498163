import { routeFromNamespace } from "./tools";
import { namespace } from "../../js/namespace";

import Index from "../../views/index/Index"
import Home from "../../views/home/Home"
import Technology from "../../views/technology/Technology"
import Token from "../../views/token/Token"
import Whitepaper from "../../views/whitepaper/Whitepaper"


const hasLanUri = false
let arr = [].concat([
  routeFromNamespace(namespace.PAGE.INDEX, Index, hasLanUri),
])

arr[0].children = [].concat([
  routeFromNamespace(namespace.PAGE.HOME, Home, hasLanUri),
  routeFromNamespace(namespace.PAGE.TECHNOLOGY, Technology, hasLanUri),
  routeFromNamespace(namespace.PAGE.TOKEN, Token, hasLanUri),
  routeFromNamespace(namespace.PAGE.WHITEPAPER, Whitepaper, hasLanUri),
])

export default arr