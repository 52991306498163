let en = {
    TheSEORToken: "The SEOR Token",
    content1: "SEOR Token is the original value carrier in SEOR Network ecosystem, providing payment methods and value support for various value transfer, staking, exchange and other scenarios.",
    content2: "SEOR Token will be mainly applied but not limited to:",
    title3: "Application Consumption",
    content3: "The fees generated by the applications in the SEOR Network are all based on $SEOR. Users should charge a certain fee or service fee for using LCDP, cross-chain bridge and Oracle.",
    title4: "Token Governance",
    content4: "SEOR Token holders can vote on protocol upgrades and parameter changes on the SEOR Network.",
    title5: "Staking",
    content5: "It is necessary to stake SEOR tokens to become the mainnet node of SEOR Network and complete the Lon Network registration.",
    title6: "Data's verification and store fees",
    content6: "When users use LON to store data on the SEOR mainnet and use LON to report the data to the mainnet for verification and storage, the LON network and the SEOR mainnet will charge fees for execution.",
    title7: "Transaction fee",
    content7: "For any transaction in SEOR Network, a certain transaction fee will be charged.",
}

let zh = {
    TheSEORToken: "The SEOR Token",
    content1: "SEOR Token is the original value carrier in SEOR Network ecosystem, providing payment methods and value support for various value transfer, staking, exchange and other scenarios.",
    content2: "SEOR Token will be mainly applied but not limited to:",
    title3: "Application Consumption",
    content3: "The fees generated by the applications in the SEOR Network are all based on $SEOR. Users should charge a certain fee or service fee for using LCDP, cross-chain bridge and Oracle.",
    title4: "Token Governance",
    content4: "SEOR Token holders can vote on protocol upgrades and parameter changes on the SEOR Network.",
    title5: "Staking",
    content5: "It is necessary to stake SEOR tokens to become the mainnet node of SEOR Network and complete the Lon Network registration.",
    title6: "Data's verification and store fees",
    content6: "When users use LON to store data on the SEOR mainnet and use LON to report the data to the mainnet for verification and storage, the LON network and the SEOR mainnet will charge fees for execution.",
    title7: "Transaction fee",
    content7: "For any transaction in SEOR Network, a certain transaction fee will be charged.",
}

let name = 'Token'

export {
    name, en, zh
}