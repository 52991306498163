import { i18nBuilder } from "../tools";

let i18n = i18nBuilder([
  require('../home/roadmap'),
  require('../home/hero'),
  require('../home/seor'),
  require('../home/framework'),
  require('../home/application'),
])

export default {
  i18n
}

