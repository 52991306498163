<template>
  <div style="height:100%">
    <Nav></Nav>
    <NavMobile></NavMobile>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "../../components/common/Nav.vue";
import NavMobile from "../../components/common/NavMobile.vue";
import Footer from "../../components/common/Footer.vue";
export default {
  components: {
    Footer,
    Nav,
    NavMobile,
  },
};
</script>

<style>

</style>