<template>
  <div class="technology-box">
    <div class="technology">
      <Layer></Layer>
      <div class="technology-introduce" id="mainnet">
        <div class="fade-in wow slideInUp left">
          <div class="left-title">
            {{ $t("Technology.Introduce.Mainnet") }}
          </div>
          <div class="left-content">
            <ul>
              <li>
                <span></span><span>{{ $t("Technology.Introduce.node") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.mechanism") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.dataSource") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.distribution") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-box">
          <div class="right">
            <img src="@/assets/technology/Mainnet@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div class="technology-mobile" id="mainnetMobile">
        <div class="left">
          <div class="left-title wow slideInUp fade-in">
            {{ $t("Technology.Introduce.Mainnet") }}
          </div>
          <div class="right">
            <img src="@/assets/technology/Mainnet@2x.png" alt="" />
          </div>
          <div class="left-content wow slideInUp fade-in">
            <ul>
              <li>
                <span></span><span>{{ $t("Technology.Introduce.node") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.mechanism") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.dataSource") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.distribution") }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="technology-introduce" id="consensus">
        <div class="fade-in wow slideInUp left">
          <div class="left-title">
            {{ $t("Technology.Introduce.Consensus") }}
          </div>
          <div class="left-content">
            <ul>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.Hybrid") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.Unlimited") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.Safety") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-box">
          <div class="right">
            <img src="@/assets/technology/oBFT_Consensus@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div class="technology-mobile" id="consensusMobile">
        <div class="left">
          <div class="left-title wow slideInUp fade-in">
            {{ $t("Technology.Introduce.Consensus") }}
          </div>
          <div class="right">
            <img src="@/assets/technology/oBFT_Consensus@2x.png" alt="" />
          </div>
          <div class="left-content wow slideInUp fade-in">
            <ul>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.Hybrid") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.Unlimited") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.Safety") }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="technology-introduce" style="padding-bottom: 0">
        <div class="fade-in wow slideInUp left">
          <div class="left-title">
            {{ $t("Technology.Introduce.TokenUtility") }}
          </div>
          <div class="left-content">
            <ul>
              <li class="special">{{ $t("Technology.Introduce.roles") }}</li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.mainnetNode") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.supplier") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.dataService") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-box">
          <div class="right">
            <img src="@/assets/technology/Token@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div class="technology-mobile" style="padding-bottom: 0">
        <div class="left">
          <div class="left-title wow slideInUp fade-in">
            {{ $t("Technology.Introduce.TokenUtility") }}
          </div>
          <div class="right">
            <img src="@/assets/technology/Token@2x.png" alt="" />
          </div>
          <div class="left-content wow slideInUp fade-in">
            <ul>
              <li>
                <span></span><span>{{ $t("Technology.Introduce.roles") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.mainnetNode") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.supplier") }}</span>
              </li>
              <li>
                <span></span
                ><span>{{ $t("Technology.Introduce.dataService") }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import Layer from "./modules/Layer.vue";

export default {
  components: {
    Layer,
  },
  data() {
    return {};
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
.fade-in {
  animation: fade-in 1.5s ease-in-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.technology-box {
  display: flex;
  align-items: center;
  justify-content: center;
  .technology {
    margin: 112px 0px;
    &-introduce {
      display: block;
      display: flex;
      padding-bottom: 176px;
      // padding-left: 32px;
      box-sizing: border-box;
      .left {
        width: 379px;
        &-title {
          font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
            sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 34px;
          line-height: 33px;
          color: #000000;
        }
        &-content {
          ul {
            padding-left: 24px;
            margin-top: 24px;
            font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.6);
          }
          li {
            list-style: none;
            display: flex;
            span:first-child {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #1a61ea;
              display: block;
              margin-top: 11px;
            }
            span:last-child {
              margin-left: 10px;
              word-break: break-word;
              flex: 1;
            }
          }
          .special {
            list-style: none;
            margin-left: -24px;
            margin-bottom: 20px;
          }
        }
      }
      .right-box {
        margin-left: 32px;
        width: 709px;
        height: 428px;
        margin-top: 60px;
        .right {
          border: 1px solid rgba(0, 0, 0, 0.06);
          width: 100%;
          height: 100%;
          img {
            width: 688px;
            height: 416px;
          }
        }
      }
    }
    &-mobile {
      display: none;
    }
  }
}
@media screen and (max-width: 780px) {
  .technology-box {
    .technology {
      padding: 0 32px;
      margin: 30px 0;
      &-introduce {
        display: none;
      }
      &-mobile {
        display: block;
        padding-bottom: 96px;
        padding-left: 0;
        .left {
          width: 100%;
          margin-top: 0px;
          &-title {
            font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
              sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: 32px;
            color: #000000;
          }
          &-content {
            ul {
              padding-left: 8px;
              margin-top: 24px;
              font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 17px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.6);
            }
            li {
              list-style: none;
              display: flex;
              span:first-child {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #1a61ea;
                display: block;
                margin-top: 10px;
              }
              span:last-child {
                margin-left: 10px;
                word-break: break-word;
                flex: 1;
              }
            }
            .special {
              list-style: none;
              margin-left: -24px;
            }
          }
        }
        .right {
          width: 100%;
          margin: 20px 0 0 0;
          height: 100%;
          border: 1px solid rgba(0, 0, 0, 0.06);
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 781px) and (max-width: 1270px) {
  .technology-box {
    .technology {
      &-introduce {
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        box-sizing: border-box;
        .left {
          width: 33%;
        }
        .right-box {
          width: 67%;
          height: auto;
          margin-left: 0;
          padding-left: 32px;
          box-sizing: border-box;
          .right {
            width: 100%;
            height: auto;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
@keyframes mymove {
  0% {
    opacity: 0;
    left: -50px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}
@-webkit-keyframes mymove {
  0% {
    opacity: 0;
    left: -50px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}
</style>