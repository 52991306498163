<template>
  <div>
    <Hero></Hero>
    <Seor></Seor>
    <Framework></Framework>
    <Application></Application>
    <Partners></Partners>
    <Roadmap></Roadmap>
  </div>
</template>

<script>
import Hero from "./modules/Hero.vue";
import Seor from "./modules/Seor.vue";
import Framework from "./modules/Framework.vue";
import Application from "./modules/Application.vue";
import Partners from "./modules/Partners.vue";
import Roadmap from "./modules/Roadmap.vue";
import { WOW } from "wowjs";
export default {
  components: {
    Hero,
    Seor,
    Framework,
    Partners,
    Roadmap,
    Application,
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live:false,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
</style>