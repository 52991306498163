let en = {
    sealOracle:"SEOR Network",
    offers:"SEOR is the next generation of decentralized Web3.0 application technology development infrastructure, which aims to provide users and developers of Web3.0 with an easy-to-use blockchain technology development platform.",
    readTheWhitepapper:"Read the Whitepapper",
    project:"Project supported by Web3.0 Foundation Grants program Powered by Polkadot",
}

let zh = {
    sealOracle:"Seal Oracle",
    offers:"SEOR为链路现实提供了一个可定制的低代码开发Oracle数据平台，具有高可扩展性和低技术阈值的特点。",
    readTheWhitepapper:"阅读白皮书",
    project:"由Polkadot提供的WEB3.0基金资助项目支持的项目",
}

let name = 'Hero'

export {
    name, en, zh
}