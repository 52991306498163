<template>
  <div class="seor-box" id="seor">
    <div class="seor">
      <div class="seor-left">
        <div class="title">{{ $t("Home.Seor.SEOR") }}</div>
        <div class="pc">
          <div class="all-content">
            <p>
              {{ $t("Home.Seor.content1") }}
            </p>
            <p>
              {{ $t("Home.Seor.content6") }}
            </p>
            <div class="problems">{{ $t("Home.Seor.problems") }}</div>
            <ul>
              <li>
                <span></span><span>{{ $t("Home.Seor.content2") }}</span>
              </li>
              <li>
                <span></span><span>{{ $t("Home.Seor.content3") }}</span>
              </li>
              <li>
                <span></span><span>{{ $t("Home.Seor.content4") }}</span>
              </li>
              <li>
                <span></span><span>{{ $t("Home.Seor.content5") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="mobile">
          <div v-show="show" class="part-content-bg">
            <div class="part-content">
              <p>
                {{ $t("Home.Seor.content1") }}
              </p>
            </div>
          </div>
          <div v-show="!show" class="all-content">
            <p>
              {{ $t("Home.Seor.content1") }}
            </p>
            <p>
              {{ $t("Home.Seor.content6") }}
            </p>
            <div class="problems">{{ $t("Home.Seor.problems") }}</div>
            <ul>
              <li>
                <span></span><span>{{ $t("Home.Seor.content2") }}</span>
              </li>
              <li>
                <span></span><span>{{ $t("Home.Seor.content3") }}</span>
              </li>
              <li>
                <span></span><span>{{ $t("Home.Seor.content4") }}</span>
              </li>
              <li>
                <span></span><span>{{ $t("Home.Seor.content5") }}</span>
              </li>
            </ul>
          </div>
          <div class="arrow-down" @click="down()" v-show="showDown">
            <img src="@/assets/home/seor/Group_37.png" alt="" />
          </div>
        </div>
      </div>
      <div class="seor-right">
        <div class="icon wow slideInUp icon1">
          <div class="icon-img">
            <img src="@/assets/home/seor/icon-multichain.png" alt="" />
          </div>
          <div class="icon-info">
            <div class="title">
              {{ $t("Home.Seor.MultiChain") }}
            </div>
            <div class="content">
              {{ $t("Home.Seor.MultiChainContent") }}
            </div>
          </div>
        </div>
        <div class="icon wow slideInUp icon2">
          <div class="icon-img">
            <img
              src="@/assets/home/seor/icon-flexible-customization.png"
              alt=""
            />
          </div>
          <div class="icon-info">
            <div class="title">
              {{ $t("Home.Seor.Flexible") }}
            </div>
            <div class="content">
              {{ $t("Home.Seor.FlexibleContent") }}
            </div>
          </div>
        </div>
        <div class="icon wow slideInUp icon3">
          <div class="icon-img">
            <img src="@/assets/home/seor/icon-multiple-modes.png" alt="" />
          </div>
          <div class="icon-info">
            <div class="title">
              {{ $t("Home.Seor.Multiple") }}
            </div>
            <div class="content">
              {{ $t("Home.Seor.MultipleContent") }}
            </div>
          </div>
        </div>
        <div class="icon wow slideInUp icon4" style="margin-bottom: 0px">
          <div class="icon-img">
            <img
              src="@/assets/home/seor/icon-compatibility-support.png"
              alt=""
            />
          </div>
          <div class="icon-info">
            <div class="title">
              {{ $t("Home.Seor.Compatibility") }}
            </div>
            <div class="content">
              {{ $t("Home.Seor.CompatibilityContent") }}
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      show: true,
      showDown: true,
    };
  },
  methods: {
    down() {
      this.show = !this.show;
      this.showDown = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.icon1 {
  position: relative;
  animation: mymove 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-animation: mymove 1.5s cubic-bezier(0.165, 0.84, 0.44, 1); /*Safari and Chrome*/
  animation-fill-mode: forwards;
}
.icon2 {
  position: relative;
  animation: mymove 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  -webkit-animation: mymove 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s; /*Safari and Chrome*/
  animation-fill-mode: forwards;
}
.icon3 {
  position: relative;
  animation: mymove 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s;
  -webkit-animation: mymove 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s; /*Safari and Chrome*/
  animation-fill-mode: forwards;
}
.icon4 {
  position: relative;
  animation: mymove 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.9s;
  -webkit-animation: mymove 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.9s; /*Safari and Chrome*/
  animation-fill-mode: forwards;
}
.seor-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 176px;
}
.seor {
  display: flex;
  &-left {
    width: 496px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    .title {
      font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 33px;
      color: #000000;
    }
    .problems {
      margin: 20px 0;
    }
    ul {
      padding-left: 8px;
    }
    li {
      list-style: none;
      display: flex;
      span:first-child {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #1a61ea;
        display: inline-block;
        margin-top: 9px;
      }
      span:last-child {
        margin-left: 10px;
        word-break: break-word;
        flex: 1;
      }
    }
    p {
      margin-top: 20px;
      line-height: 24px;
    }
    .mobile {
      display: none;
    }
    .arrow-down {
      display: none;
    }
  }
  &-right {
    margin-left: 87px;
    width: 496px;
    position: relative;
    .icon {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 48px;
      opacity: 0;
    }
    .icon-img {
      width: 64px;
      height: 64px;
      margin-right: 24px;
      img {
        width: 64px;
        height: 64px;
      }
    }
    .icon-info {
      width: 408px;
      .title {
        height: 24px;
        font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
          sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }
      .content {
        margin-top: 12px;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

@keyframes mymove {
  0% {
    opacity: 0;
    right: 0px;
  }
  100% {
    opacity: 1;
    right: 0px;
  }
}
@-webkit-keyframes mymove {
  0% {
    opacity: 0;
    right: 0px;
  }
  100% {
    opacity: 1;
    right: 0px;
  }
}
@media screen and (max-width: 375px) {
  .seor {
    &-left {
      .part-content {
        height: 166px;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .seor-box {
    padding-bottom: 88px;
  }
  .seor {
    width: 100%;
    height: 100%;
    flex-flow: column;
    display: flex;
    &-left {
      width: 100%;
      padding: 0 32px;
      box-sizing: border-box;
      .title {
        font-size: 28px;
        line-height: 32px;
      }
      .problems {
        margin-top: 24px;
      }
      .pc {
        display: none;
      }
      .mobile {
        display: block;
      }
      .part-content {
        width: 100%;
        height: 140px;
        overflow: hidden;
      }
      .part-content-bg {
        position: relative;
      }
      .part-content-bg:after {
        content: "ecosystems cross-chain to the";
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        bottom: -25px;
        left: 0;
        position: absolute;
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.6) 15.38%,
          rgba(0, 0, 0, 0) 80.83%
        );
        z-index: 9;
      }
      .arrow-down {
        display: block;
        margin-top: 32px;
        img {
          margin: 0 auto;
          display: block;
        }
      }
    }
    &-right {
      width: 100%;
      margin: 48px 0 0 0;
      padding: 0 32px;
      box-sizing: border-box;
      .icon-info {
        flex: 1;
        .title {
          font-size: 15px;
          line-height: 20px;
        }
        .content {
          margin-top: 8px;
        }
      }
    }
  }
}
@media screen and (min-width: 450px) and (max-width: 780px) {
  .mobile {
    display: none;
  }
  .PC {
    display: block;
  }
  .seor {
    flex-direction: column;
    padding: 0 32px;
    box-sizing: border-box;
    &-left {
      width: 100%;
    }
    &-right {
      margin: 40px 0 0 0;
      width: 100%;
      .icon {
        justify-content: inherit;
        .icon-info {
          width: 72%;
        }
      }
    }
  }
  .seor-box {
    justify-content: inherit;
    padding-left: 32px;
    padding-bottom: 84px;
  }
}
@media screen and (min-width: 780px) and (max-width: 1140px) {
  .seor-box {
    padding-bottom: 88px;
  }
  .seor {
    p {
        line-height: 20px;
      }
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
    box-sizing: border-box;
    &-left {
      width: 46%;
      font-size: 15px;
      line-height: 20px;
      
    }
    &-right {
      width: 46%;
      margin: 0;
    }
  }
}
</style>