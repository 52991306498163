function routeFromNamespace(namespace, component, hasLanUri = false, routerName = 'default') {
    let name = namespace.name
    let uri = namespace.uri
    let uriParam = namespace.uriParam || ''

    let namedComponent = {}
    namedComponent[routerName] = component

    let langUri = ''
    if (hasLanUri) {
        langUri = '/:lang'
    }

    let routeEl = {
        path: langUri + uri + uriParam, name: name, components: namedComponent
    }

    if (namespace.redirect) {
        routeEl.redirect = namespace.redirect
    }
    return routeEl
}

export {
    routeFromNamespace
}