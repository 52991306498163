var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"seor-token-box"},[_c('div',{staticClass:"seor-token"},[_vm._m(0),_c('div',{staticClass:"introduce"},[_c('div',{staticClass:"introduce-title"},[_vm._v(_vm._s(_vm.$t("Token.Token.TheSEORToken")))]),_c('div',{staticClass:"introduce-content"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("Token.Token.content1"))+" ")]),_c('p',{staticStyle:{"margin-top":"12px"}},[_vm._v(" "+_vm._s(_vm.$t("Token.Token.content2"))+" ")])])]),_c('div',{staticClass:"photo-text"},[_c('div',{staticClass:"photo-box"},[_vm._m(1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Token.Token.title3")))]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t("Token.Token.content3"))+" ")])]),_c('div',{staticClass:"photo-box"},[_vm._m(2),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Token.Token.title4")))]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t("Token.Token.content4"))+" ")])]),_c('div',{staticClass:"photo-box"},[_vm._m(3),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Token.Token.title5")))]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t("Token.Token.content5"))+" ")])]),_c('div',{staticClass:"photo-box"},[_vm._m(4),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Token.Token.title6")))]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t("Token.Token.content6"))+" ")])]),_c('div',{staticClass:"photo-box"},[_vm._m(5),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Token.Token.title7")))]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t("Token.Token.content7"))+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{staticClass:"banner2 wow slideInUp slideLeft1",attrs:{"src":require("@/assets/home/token/img-token-2.png"),"alt":""}}),_c('img',{staticClass:"banner1 wow slideInUp slideLeft",attrs:{"src":require("@/assets/home/token/img-token-1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":require("@/assets/home/token/icon-consumption.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":require("@/assets/home/token/icon-building.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":require("@/assets/home/token/icon-staking.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":require("@/assets/home/token/icon-right.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-img"},[_c('img',{attrs:{"src":require("@/assets/home/token/icon-arrow.png"),"alt":""}})])
}]

export { render, staticRenderFns }