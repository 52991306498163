let en = {
    TechnicalFramework: "Technical Framework",
    SEORTechnology: "Learn more about SEOR's technology",
    Lightweight: "Lightweight Short Chain Technology",
    LightweightContent: "The self-innovated LON technology can rapidly support data verification and store the evidence of data authentication.",
    Micro: "Lightweight Oracle Network",
    MicroContent: "A short chain system created to support LON.",
    Layered: "Layered Modular Microservice Architecture",
    LayeredContent: "Application of microservice architecture technology similar to SaaS.",
    Governance: "Cross-chain Governance",
    GovernanceContent: "The self-innovated LON technology can rapidly support data verification and store the evidence of data authentication.",
}

let zh = {
    TechnicalFramework: "技术框架",
    SEORTechnology: "了解更多关于SEOR技术的信息",
    Lightweight: "轻量级短链技术",
    LightweightContent: "自主创新的LON技术可以快速支持数据验证和存储数据认证证据。",
    Micro: "微型预言机",
    MicroContent: "为支持LON而创建的短链系统。",
    Layered: "分层模块化微服务体系结构",
    LayeredContent: "与SaaS类似的微服务架构技术的应用。",
    Governance: "跨链治理",
    GovernanceContent: "自主创新的LON技术可以快速支持数据验证和存储数据认证证据。",
}

let name = 'Framework'

export {
    name, en, zh
}