<template>
  <div>
    <div class="layer" id="layer">
      <div class="layer-left fade-in">
        <div class="title">{{ $t("Technology.Layer.middleLayer") }}</div>
        <div class="content">
          <div>
            {{ $t("Technology.Layer.content1") }}
          </div>
          <br />
          <div>
            {{ $t("Technology.Layer.content2") }}
          </div>
        </div>
      </div>
      <div class="layer-right">
        <img src="@/assets/technology/The Middle Layer@2x.png" alt="" />
      </div>
    </div>
    <div class="mobile" id="layerMobile">
      <div class="mobile-left">
        <div class="title fade-in">
          {{ $t("Technology.Layer.middleLayer") }}
        </div>
        <div class="mobile-right">
          <img src="@/assets/technology/The_Middle_Layer.png" alt="" />
        </div>
        <div class="content fade-in">
          <div>
            {{ $t("Technology.Layer.content1") }}
          </div>
          <br />
          <div>
            {{ $t("Technology.Layer.content2") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.fade-in {
  animation: fade-in 1s ease-in-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.layer {
  display: block;
  width: 100%;
  display: flex;
  padding-bottom: 176px;
  &-right {
    width: 736px;
    height: 614px;
    margin-left: 32px;
    img {
      width: 100%;
      height: 100%;
      margin-top: 60px;
    }
  }
  &-left {
    margin-top: 15px;
    .title {
      font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 33px;
      color: #000000;
    }
    .content {
      width: 360px;
      height: 328px;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 20px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 24px;
    }
  }
}
.mobile {
  display: none;
}
@media screen and (max-width: 780px) {
  .mobile {
    display: block;
    box-sizing: border-box;
    padding-bottom: 96px;
    .aa {
      animation: fade-in 1s ease-in-out both;
    }
    &-left {
      margin-top: 0;
      .title {
        font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
          sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 32px;
        color: #000000;
      }
      .content {
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 17px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.6);
        margin-top: 24px;
      }
    }
    &-right {
      width: 100%;
      margin: 24px 0 0 0;
      height: auto;
      margin-left: -2.6%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .layer {
    display: none;
  }
}

@media screen and (min-width: 781px) and (max-width: 1270px) {
  .layer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
    &-left {
      .title {
        font-size: 28px;
        line-height: 32px;
      }
      width: 33%;
      .content {
        width: 100%;
        font-size: 17px;
        line-height: 22px;
      }
    }
    &-right {
      width: 67%;
      height: auto;
      margin-left: 0;
      padding-left: 16px;
      box-sizing: border-box;
      img {
        width: 100%;
        height: auto;
        margin-top: 62px;
      }
    }
  }
}
</style>