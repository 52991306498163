<template>
  <div class="nav-bg-box">
    <div class="nav-bg">
      <nav class="nav-bar">
        <div class="nav-img">
          <router-link :to="{ path: '/' }">
            <img src="@/assets/nav/logo-seor.png" alt="" />
            <!-- <span class="title">SEOR</span> -->
          </router-link>
        </div>
        <div class="nav-title">
          <ul class="switch-menu">
            <li @click="toHome">{{ $t("Nav.Nav.Home") }}</li>
            <li @click="toTechnology">{{ $t("Nav.Nav.Technology") }}</li>
            <li @click="toToken">{{ $t("Nav.Nav.Token") }}</li>
            <li v-show="!docClick" class="arrow-doc">
              <span class="drop-doc">Ecosystem</span>
            </li>
            <li v-show="docClick" class="arrow-bg-doc">
              <span class="drop-doc">Ecosystem</span>
            </li>
            <li v-show="!userClick" class="arrow">
              <span class="drop-menu">{{ $t("Nav.Nav.Documentation") }}</span>
            </li>
            <li v-show="userClick" class="arrow-bg">
              <span class="drop-menu">{{ $t("Nav.Nav.Documentation") }}</span>
            </li>
          </ul>
          <div v-show="userClick" class="drop-list">
            <ul>
              <li>
                <a
                  target="_blank"
                  style="color: #000; cursor: pointer"
                  href="https://contact-97.gitbook.io/seor-network/"
                  >Overview</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  style="color: #000; cursor: pointer"
                  href="https://static-files.seor.io/SEOR-Whitepaper.v3.0.pdf"
                  >{{ $t("Nav.Nav.Whitepaper") }}</a
                >
              </li>
              <!-- <li>
                <a
                  target="_blank"
                  style="color: #000; cursor: pointer"
                  href="https://docsend.com/view/j7amdrwc353n957a"
                  >{{ $t("Nav.Nav.Whitepaper") }}</a
                >
              </li> -->
              <li>
                <a
                  target="_blank"
                  style="color: #000; cursor: pointer"
                  href="https://docsend.com/view/p7v9yng9jkjf4mzd"
                  >{{ $t("Nav.Nav.Introduction") }}</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  style="color: #000; cursor: pointer"
                  href="https://docsend.com/view/emchpkyswwvssfdw"
                  >{{ $t("Nav.Nav.Deck") }}</a
                >
              </li>
            </ul>
          </div>
          <div v-show="docClick" class="drop-list-doc">
            <ul>
              <li>
                <a
                  target="_blank"
                  style="color: #000; cursor: pointer"
                  href="https://sea.seor.io/"
                  >SEORSEA</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userClick: false,
      docClick: false,
    };
  },
  methods: {
    toHome() {
      this.$router.push({ name: "index" });
      document.documentElement.scrollTop = 0;
    },
    toTechnology() {
      this.$router.push({ name: "technology" });
      document.documentElement.scrollTop = 0;
    },
    toToken() {
      this.$router.push({ name: "token" });
      document.documentElement.scrollTop = 0;
    },
    toWhitepaper() {
      this.$router.push({ name: "whitepaper" });
    },
    popMenu() {}
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (
        e.target.className == "drop-menu" ||
        e.target.className == "arrow" ||
        e.target.className == "arrow-bg"
      ) {
        this.userClick = !this.userClick;
      } else {
        this.userClick = false;
      }
      if (
        e.target.className == "drop-doc" ||
        e.target.className == "arrow-doc" ||
        e.target.className == "arrow-bg-doc"
      ) {
        this.docClick = !this.docClick;
      } else {
        this.docClick = false;
      }
    });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.nav-bg-box {
  width: 100%;
  height: 72px;
  background-color: #fff;
  z-index: 999;
}
.nav-bg {
  width: 100%;
  height: 72px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  .nav-bar {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    position: relative;
    .nav-img {
      position: absolute;
      top: 18px;
      left: 20px;
      cursor: pointer;
      img {
        vertical-align: bottom;
        width: 131px;
        height: auto;
      }
    }
  }
  .nav-title {
    position: relative;
    img {
      width: 16px;
      height: auto;
      display: inline-block;
      vertical-align: top;
      margin-top: 1px;
    }
  }
  .switch-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    li:first-child {
      margin-left: 0;
    }
    li {
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      list-style: none;
      margin-left: 64px;
      text-align: center;
      cursor: pointer;
      height: 20px;
      &:hover {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .arrow {
    background: url("../../assets/nav/menu_arrow.png") no-repeat right;
    padding-right: 19px;
    background-size: 16px;
    box-sizing: border-box;
  }
  .arrow:hover {
    background: url("../../assets/nav/chevron-up.png") no-repeat right;
    background-size: 16px;
  }
  .arrow:active {
    background: url("../../assets/nav/menu_arrow.png") no-repeat right;
    background-size: 16px;
    color: #000 !important;
  }
  .arrow-bg {
    background: url("../../assets/nav/menu_up.png") no-repeat right;
    padding-right: 19px;
    background-size: 16px;
    box-sizing: border-box;
  }
  .arrow-bg:hover {
    background: url("../../assets/nav/chevron-up.svg") no-repeat right;
    background-size: 16px;
  }
  .arrow-bg:active {
    background: url("../../assets/nav/menu_up.png") no-repeat right;
    background-size: 16px;
    color: #000 !important;
  }
  .arrow-doc {
    background: url("../../assets/nav/menu_arrow.png") no-repeat right;
    padding-right: 19px;
    background-size: 16px;
    box-sizing: border-box;
  }
  .arrow-doc:hover {
    background: url("../../assets/nav/chevron-up.png") no-repeat right;
    background-size: 16px;
  }
  .arrow-doc:active {
    background: url("../../assets/nav/menu_arrow.png") no-repeat right;
    background-size: 16px;
    color: #000 !important;
  }
  .arrow-bg-doc {
    background: url("../../assets/nav/menu_up.png") no-repeat right;
    padding-right: 19px;
    background-size: 16px;
    box-sizing: border-box;
  }
  .arrow-bg-doc:hover {
    background: url("../../assets/nav/chevron-up.svg") no-repeat right;
    background-size: 16px;
  }
  .arrow-bg-doc:active {
    background: url("../../assets/nav/menu_up.png") no-repeat right;
    background-size: 16px;
    color: #000 !important;
  }
  .drop-list {
    position: absolute;
    top: 36px;
    right: -80px;
    position: absolute;
    width: 220px;
    height: 96px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
    animation: myfirst 0.5s;
    -webkit-animation: myfirst 0.5s;
    animation-fill-mode: forwards;
    ul {
    }
    li {
      margin-left: 0;
      text-align: center;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      color: #000000;
      list-style: none;
    }
    li:hover {
      background-color: #fafafa;
      color: rgba(26, 97, 234, 1);
      a {
        color: rgba(26, 97, 234, 1) !important;
      }
    }
    li:active {
      background-color: #fafafa;
      color: #000000;
      a {
        color: #000000 !important;
      }
    }
  }
  .drop-list-doc {
    position: absolute;
    top: 36px;
    right: 62px;
    position: absolute;
    width: 220px;
    // height: 96px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
      0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
    animation: myfirstdoc 0.5s;
    -webkit-animation: myfirstdoc 0.5s;
    animation-fill-mode: forwards;
    ul {
    }
    li {
      margin-left: 0;
      text-align: center;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      color: #000000;
      list-style: none;
    }
    li:hover {
      background-color: #fafafa;
      color: rgba(26, 97, 234, 1);
      a {
        color: rgba(26, 97, 234, 1) !important;
      }
    }
    li:active {
      background-color: #fafafa;
      color: #000000;
      a {
        color: #000000 !important;
      }
    }
  }
}
@media screen and (max-width: 780px) {
  .nav-bg-box {
    display: none;
  }
}
@media screen and (min-width: 780px) and (max-width: 1140px) {
  .nav-bg {
    .nav-bar {
      padding-left: 100px;
    }
  }
}
@-webkit-keyframes myfirst {
  0% {
    height: 0px;
  }
  100% {
    height: 192px;
  }
}
@-webkit-keyframes myfirstdoc {
  0% {
    height: 0px;
  }
  100% {
    height: 48px;
  }
}
</style>
