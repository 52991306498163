const langUriPrefix = "/:lang "

let namespace = {
  STORE: {
  },
  PAGE: {
    INDEX: { name: 'index', uri: '/', redirect: '/home' },
    HOME: { name: 'home', uri: '/home' },
    TECHNOLOGY: { name: 'technology', uri: '/technology' },
    TOKEN: { name: 'token', uri: '/token' },
    WHITEPAPER: { name: 'whitepaper', uri: "/static/whitePaper/SEAL-ORACLE WhitePaper 1.0.pdf", }
  }
}


export {
  namespace
}