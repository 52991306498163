<template>
  <div class="seor-token-box">
    <div class="seor-token">
      <div class="logo">
        <img
          src="@/assets/home/token/img-token-2.png"
          alt=""
          class="banner2 wow slideInUp slideLeft1"
        />
        <img
          src="@/assets/home/token/img-token-1.png"
          alt=""
          class="banner1 wow slideInUp slideLeft"
        />
      </div>
      <div class="introduce">
        <div class="introduce-title">{{ $t("Token.Token.TheSEORToken") }}</div>
        <div class="introduce-content">
          <p>
            {{ $t("Token.Token.content1") }}
          </p>
          <p style="margin-top: 12px">
            {{ $t("Token.Token.content2") }}
          </p>
        </div>
      </div>
      <div class="photo-text">
        <div class="photo-box">
          <div class="box-img">
            <img src="@/assets/home/token/icon-consumption.png" alt="" />
          </div>
          <div class="title">{{ $t("Token.Token.title3") }}</div>
          <div class="content">
            {{ $t("Token.Token.content3") }}
          </div>
        </div>
        <div class="photo-box">
          <div class="box-img">
            <img src="@/assets/home/token/icon-building.png" alt="" />
          </div>
          <div class="title">{{ $t("Token.Token.title4") }}</div>
          <div class="content">
            {{ $t("Token.Token.content4") }}
          </div>
        </div>
        <div class="photo-box">
          <div class="box-img">
            <img src="@/assets/home/token/icon-staking.png" alt="" />
          </div>
          <div class="title">{{ $t("Token.Token.title5") }}</div>
          <div class="content">
            {{ $t("Token.Token.content5") }}
          </div>
        </div>
        <div class="photo-box">
          <div class="box-img">
            <img src="@/assets/home/token/icon-right.png" alt="" />
          </div>
          <div class="title">{{ $t("Token.Token.title6") }}</div>
          <div class="content">
            {{ $t("Token.Token.content6") }}
          </div>
        </div>
        <div class="photo-box">
          <div class="box-img">
            <img src="@/assets/home/token/icon-arrow.png" alt="" />
          </div>
          <div class="title">{{ $t("Token.Token.title7") }}</div>
          <div class="content">
            {{ $t("Token.Token.content7") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  methods: {
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live:false,
    });
    wow.init();
  },
};
</script>
<style lang="scss" scoped>
.slideLeft {
  animation: rightmove 1s, ease-in-out;
  -webkit-animation: rightmove 1s, ease-in-out; /*Safari and Chrome*/
  animation-fill-mode: forwards;
}
.slideLeft1 {
  animation: rightmove 2s, ease-in-out;
  -webkit-animation: rightmove 2s, ease-in-out; /*Safari and Chrome*/
  animation-fill-mode: forwards;
}
.seor-token-box {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.seor-token {
  .logo {
    // padding: 88px 0 112px;
    width: 320px;
    height: 320px;
    position: relative;
    margin: 88px auto 112px;
    img {
      position: absolute;
      width: 320px;
      height: auto;
      top: 0;
      left: 0;
    }
    .banner1 {
      z-index: 1;
    }
    .banner2 {
      z-index: 2;
    }
  }
  .introduce {
    width: 680px;
    padding-bottom: 64px;
    &-title {
      width: 100%;
      height: 33px;
      font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;;
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 33px;
      text-align: center;
      color: #000000;
      margin-bottom: 32px;
    }
    &-content {
      width: 100%;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .photo-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 680px;
    .photo-box {
      width: 320px;
      padding-bottom: 64px;
      .box-img {
        img {
          width: 64px;
          height: auto;
        }
        margin-bottom: 24px;
      }
      .title {
        font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        height: 24px;
        margin-bottom: 12px;
      }
      .content {
        width: 100%;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
@media screen and (max-width: 780px) {
  .seor-token {
    .logo {
      margin: 64px auto 72px;
      width: 160px;
      height: 160px;
      img {
        width: 160px;
        height: auto;
      }
    }
    .introduce {
      width: 100%;
      padding: 0 20px 45px 20px;
      box-sizing: border-box;
    }
    .photo-text {
      width: 100%;
      padding: 0 20px 45px 20px;
      box-sizing: border-box;
      .photo-box {
        width: 100%;
        .box-img {
          margin-bottom: 12px;
          img {
            width: 32px;
            height: auto;
          }
        }
      }
    }
  }
}
@keyframes rightmove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes rightmove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>