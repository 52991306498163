<template>
  <div class="partners" id="partners">
    <div class="partners-title">Collaborative Partners</div>
    <div class="img-pc">
      <div class="PC">
        <div class="scroll text4  wow slideInUp fade-in">
          <div>
            <span
              v-for="(item, index) in imgUrl"
              :key="index"
              class="img-column"
            >
              <span
                class="img-case"
                v-for="(img, index) in item.imgArr"
                :key="index"
              >
                <img :src="img" alt="" /> </span
            ></span>
          </div>
          <div>
            <span
              v-for="(item, index) in imgUrl"
              :key="index"
              class="img-column"
            >
              <span
                class="img-case"
                v-for="(img, index) in item.imgArr"
                :key="index"
              >
                <img :src="img" alt="" /> </span
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="img-mobile">
      <div class="PC">
        <div class="scroll text4  wow slideInUp fade-in">
          <div>
            <span
              v-for="(item, index) in imgUrl1"
              :key="index"
              class="img-column"
            >
              <span
                class="img-case"
                v-for="(img, index) in item.imgArr"
                :key="index"
              >
                <img :src="img" alt="" /> </span
            ></span>
          </div>
          <div>
            <span
              v-for="(item, index) in imgUrl1"
              :key="index"
              class="img-column"
            >
              <span
                class="img-case"
                v-for="(img, index) in item.imgArr"
                :key="index"
              >
                <img :src="img" alt="" /> </span
            ></span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="partners-img">
      <div v-for="(item, index) in imgUrl" :key="index" class="img-column">
        <div class="img-case" v-for="(img, index) in item.imgArr" :key="index">
          <img :src="img" alt="" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  computed: {},
  components: {
    swiper,
    swiperSlide,
  },
  created() {
    let browser = {
      versions: (function () {
        var u = navigator.userAgent,
          app = navigator.appVersion;

        return {
          //移动终端浏览器版本信息

          trident: u.indexOf("Trident") > -1, //IE内核

          presto: u.indexOf("Presto") > -1, //opera内核

          webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核

          gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核

          mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端

          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端

          android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器

          iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器

          iPad: u.indexOf("iPad") > -1, //是否iPad

          webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
        };
      })(),
      language: (navigator.browserLanguage || navigator.language).toLowerCase(),
    };
    if (
      browser.versions.mobile ||
      browser.versions.ios ||
      browser.versions.android ||
      browser.versions.iPhone ||
      browser.versions.iPad
    ) {
      this.swiperOption.slidesPerView = 3;
    } else {
      this.swiperOption.slidesPerView = 6;
    }
  },
  data() {
    return {
      showPc: true,
      swiperOption: {
        notNextTick: true,
        // direction: "vertical",
        speed: 2000,
        // setWrapperSize: true,
        freeMode: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        loop: true,
        observer: true,
        observeParents: true,
        // spaceBetween: 4,
        slidesPerView: 6,
      },
      imgUrl: [
        {
          imgArr: [
            require("@/assets/home/partners/X21.png"),
            require("@/assets/home/partners/aad-ventures.png"),
            require("@/assets/home/partners/aha-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/almora-capital.png"),
            require("@/assets/home/partners/alves-venture.png"),
            require("@/assets/home/partners/ama-lover-club.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/anypad.png"),
            require("@/assets/home/partners/av-star.png"),
            require("@/assets/home/partners/bca-investments.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/blackdragon.png"),
            require("@/assets/home/partners/blago-capital.png"),
            require("@/assets/home/partners/Booster.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/bsclaunch.png"),
            require("@/assets/home/partners/CCC.png"),
            require("@/assets/home/partners/chain-ridge-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/cmc-capital.png"),
            require("@/assets/home/partners/Criterion.png"),
            require("@/assets/home/partners/crt-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/crypto stalkers.png"),
            require("@/assets/home/partners/crypto-players.png"),
            require("@/assets/home/partners/cryptoiz.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/dec-ventures.png"),
            require("@/assets/home/partners/DFG.png"),
            require("@/assets/home/partners/dreamboat-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/dutch-crypto-investors.png"),
            require("@/assets/home/partners/fairum.png"),
            require("@/assets/home/partners/fbg-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/fish-dao.png"),
            require("@/assets/home/partners/ghdao.png"),
            require("@/assets/home/partners/hidden-gems-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/ico-pantera.png"),
            require("@/assets/home/partners/Incrypto-trader.png"),
            require("@/assets/home/partners/insignius-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/itsblockchain.png"),
            require("@/assets/home/partners/kangaroo-capital.png"),
            require("@/assets/home/partners/kenzo-ventures.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/kretos-ventures.png"),
            require("@/assets/home/partners/Lightning.png"),
            require("@/assets/home/partners/master-ventures.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/Megala.png"),
            require("@/assets/home/partners/mh-ventures.png"),
            require("@/assets/home/partners/minted-lab.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/mmicg.png"),
            require("@/assets/home/partners/mw-partner.png"),
            require("@/assets/home/partners/newtribe-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/octopus.png"),
            require("@/assets/home/partners/oracles-investment-group.png"),
            require("@/assets/home/partners/oxbull.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/pinnacle-venture-capital.png"),
            require("@/assets/home/partners/Polygon.png"),
            require("@/assets/home/partners/rr2.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/ScamDunk.png"),
            require("@/assets/home/partners/signal-ventures.png"),
            require("@/assets/home/partners/signum-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/sl2-capital.png"),
            require("@/assets/home/partners/sm.png"),
            require("@/assets/home/partners/space-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/starter-capital.png"),
            require("@/assets/home/partners/synapse-network.png"),
            require("@/assets/home/partners/TurePNL.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/v99z.png"),
            require("@/assets/home/partners/web3-foundation.png"),
            require("@/assets/home/partners/whitelist-ventures.png"),
          ],
        },
      ],
      imgUrl1: [
        {
          imgArr: [
            require("@/assets/home/partners/X21.png"),
            require("@/assets/home/partners/aad-ventures.png"),
            require("@/assets/home/partners/aha-capital.png"),
            require("@/assets/home/partners/almora-capital.png"),
            require("@/assets/home/partners/alves-venture.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/anypad.png"),
            require("@/assets/home/partners/av-star.png"),
            require("@/assets/home/partners/bca-investments.png"),
            require("@/assets/home/partners/ama-lover-club.png"),
            require("@/assets/home/partners/blackdragon.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/bsclaunch.png"),
            require("@/assets/home/partners/CCC.png"),
            require("@/assets/home/partners/chain-ridge-capital.png"),
            require("@/assets/home/partners/blago-capital.png"),
            require("@/assets/home/partners/Booster.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/cmc-capital.png"),
            require("@/assets/home/partners/Criterion.png"),
            require("@/assets/home/partners/crt-capital.png"),
            require("@/assets/home/partners/crypto stalkers.png"),
            require("@/assets/home/partners/crypto-players.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/dec-ventures.png"),
            require("@/assets/home/partners/DFG.png"),
            require("@/assets/home/partners/dreamboat-capital.png"),
            require("@/assets/home/partners/cryptoiz.png"),
            require("@/assets/home/partners/dutch-crypto-investors.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/fish-dao.png"),
            require("@/assets/home/partners/ghdao.png"),
            require("@/assets/home/partners/hidden-gems-capital.png"),
            require("@/assets/home/partners/fairum.png"),
            require("@/assets/home/partners/fbg-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/ico-pantera.png"),
            require("@/assets/home/partners/Incrypto-trader.png"),
            require("@/assets/home/partners/insignius-capital.png"),
            require("@/assets/home/partners/itsblockchain.png"),
            require("@/assets/home/partners/kangaroo-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/kretos-ventures.png"),
            require("@/assets/home/partners/Lightning.png"),
            require("@/assets/home/partners/master-ventures.png"),
            require("@/assets/home/partners/kenzo-ventures.png"),
            require("@/assets/home/partners/Megala.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/mmicg.png"),
            require("@/assets/home/partners/mw-partner.png"),
            require("@/assets/home/partners/newtribe-capital.png"),
            require("@/assets/home/partners/mh-ventures.png"),
            require("@/assets/home/partners/minted-lab.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/pinnacle-venture-capital.png"),
            require("@/assets/home/partners/Polygon.png"),
            require("@/assets/home/partners/octopus.png"),
            require("@/assets/home/partners/oracles-investment-group.png"),
            require("@/assets/home/partners/oxbull.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/ScamDunk.png"),
            require("@/assets/home/partners/signal-ventures.png"),
            require("@/assets/home/partners/signum-capital.png"),
            require("@/assets/home/partners/rr2.png"),
            require("@/assets/home/partners/sl2-capital.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/sm.png"),
            require("@/assets/home/partners/space-capital.png"),
            require("@/assets/home/partners/starter-capital.png"),
            require("@/assets/home/partners/synapse-network.png"),
            require("@/assets/home/partners/TurePNL.png"),
          ],
        },
        {
          imgArr: [
            require("@/assets/home/partners/v99z.png"),
            require("@/assets/home/partners/web3-foundation.png"),
            require("@/assets/home/partners/whitelist-ventures.png"),
            require("@/assets/home/partners/Incrypto-trader.png"),//repeat
            require("@/assets/home/partners/insignius-capital.png"),//repeat
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.fade-in {
  animation: fade-in 1s ease-in-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.partners {
  margin-bottom: 176px;
  &-title {
    font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 33px;
    width: 100%;
    height: 33px;
    text-align: center;
    color: #000000;
  }
  .img-pc {
    display: block;
  }
  .img-mobile {
    display: none;
  }
  .PC {
    display: block;
    margin-top: 64px;
    background: linear-gradient(117.24deg, #1ac0ea 12.56%, #1a61ea 88.5%);
    overflow: hidden;
    padding: 64px 0 16px;
  }
  .swiper-container {
    width: 100%;
    // height: 464px;
    overflow: hidden;

    padding-top: 64px;
    box-sizing: border-box;
  }
  &-img {
    display: none;
  }
  .img-case {
    width: 160px;
    height: 80px;
    box-sizing: border-box;
    display: block;
    margin: 0 48px 48px 0;
    img {
      height: auto;
      width: 160px;
      display: block;
      margin: 16px 0 0 0;
    }
  }
}
@media screen and (max-width: 780px) {
  .partners {
    .PC {
      display: block;
      margin-top: 64px;
      width: 100%;
      background: linear-gradient(117.24deg, #1ac0ea 12.56%, #1a61ea 88.5%);
      padding: 32px 0 32px 0px;
      box-sizing: border-box;
      display: flex;
      padding-top: 64px;
      .img-column {
        flex-shrink: 0;
      }
      .img-case {
        width: 112px;
        height: 56px;
        box-sizing: border-box;
        // display: flex;
        // align-items: center;
        margin: 0 32px 32px 0;
        display: block;
        img {
          display: block;
          height: auto;
          width: 112px;
          margin: 12px 0 0 0;
        }
      }
    }
    .img-pc {
      display: none;
    }
    .img-mobile {
      display: block;
    }
    .swiper-container {
      padding-top: 10px;
    }
    margin-bottom: 88px;
    &-title {
      font-size: 28px;
      line-height: 32px;
    }
  }
}

.scroll {
  display: flex;
  width: 100%;
  div {
    font-weight: 900;
    font-size: 4em;
    text-transform: uppercase;
    white-space: nowrap;
    color: #212121;
    animation: animate 100s -100s linear infinite;
    will-change: transform;

    &:nth-child(2) {
      animation: animate2 100s -50s linear infinite;
    }
    display: flex;
  }

  span {
    -webkit-text-stroke: 2px #000000;
    color: #ffffff;
  }
}

.text1 {
  transform: rotate(30deg) translate(-200px, 200px);
}

.text2 {
  transform: rotate(-25deg) translate(-200px, 120px);
}

.text3 {
  transform: rotate(25deg) translate(-200px, 300px);
}

.text3 {
  transform: rotate(-2deg) translate(-200px, -150px);
}

.text5 {
  transform: rotate(5deg) translate(-200px, 250px);
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
</style>