import { i18nBuilder } from "../tools";

let i18n = i18nBuilder([
    require('./nav'),
])

export default {
    i18n
}

