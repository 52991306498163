<template>
  <div class="nav-bg-box">
    <div class="nav-bg">
      <nav class="nav-bar">
        <div class="nav-img">
          <router-link :to="{ path: '/' }">
            <img src="@/assets/nav/logo-seor (1).png" alt="" />
          </router-link>
        </div>
        <img
          class="menu-arrow-down"
          @click="popMenu()"
          src="../../assets/icon-menu.svg"
        />
        <div v-show="userClick" class="nav-title">
          <ul class="switch-menu">
            <li @click="toHome">{{ $t("Nav.Nav.Home") }}</li>
            <li @click="toTechnology">{{ $t("Nav.Nav.Technology") }}</li>
            <li @click="toToken">{{ $t("Nav.Nav.Token") }}</li>
            <li @click="documentMenuDoc()">
              <span class="drop-menu-doc">Ecosystem</span>
              <img
                v-show="!documentShowDoc"
                class="drop-menu-doc"
                src="@/assets/nav/menu_arrow.png"
                alt=""
              />
              <img
                v-show="documentShowDoc"
                class="drop-menu-doc"
                src="@/assets/nav/menu_up.png"
                alt=""
              />
              <div v-show="documentShowDoc" class="drop-list-doc">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      style="color: #000; cursor: pointer"
                      href="https://sea.seor.io/"
                      >SEORSEA</a
                    >
                  </li>
                </ul>
              </div>
            </li>
            <li @click="documentMenu()">
              <span class="drop-menu">{{ $t("Nav.Nav.Documentation") }}</span>
              <img
                v-show="!documentShow"
                class="drop-menu"
                src="@/assets/nav/menu_arrow.png"
                alt=""
              />
              <img
                v-show="documentShow"
                class="drop-menu"
                src="@/assets/nav/menu_up.png"
                alt=""
              />
              <div v-show="documentShow" class="drop-list">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      style="color: #000; cursor: pointer"
                      href="https://contact-97.gitbook.io/seor-network/"
                      >Overview</a
                    >
                  </li>
                  <li>
                    <a
                      target="_blank"
                      style="color: #000; cursor: pointer"
                      href="https://static-files.seor.io/SEOR-Whitepaper.v3.0.pdf"
                      >{{ $t("Nav.Nav.Whitepaper") }}</a
                    >
                  </li>
                  <li>
                    <a
                      target="_blank"
                      style="color: #000; cursor: pointer"
                      href="https://docsend.com/view/p7v9yng9jkjf4mzd"
                      >{{ $t("Nav.Nav.Introduction") }}</a
                    >
                  </li>
                  <li>
                    <a
                      target="_blank"
                      style="color: #000; cursor: pointer"
                      href="https://docsend.com/view/emchpkyswwvssfdw"
                      >{{ $t("Nav.Nav.Deck") }}</a
                    >
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userClick: false,
      documentShow: false,
      documentShowDoc: false,
    };
  },
  methods: {
    toHome() {
      this.$router.push({ name: "index" });
      document.documentElement.scrollTop = 0;
      this.userClick = false;
    },
    toTechnology() {
      this.$router.push({ name: "technology" });
      document.documentElement.scrollTop = 0;
      this.userClick = false;
    },
    toToken() {
      this.$router.push({ name: "token" });
      document.documentElement.scrollTop = 0;
      this.userClick = false;
    },
    toWhitepaper() {
      this.$router.push({ name: "whitepaper" });
      this.userClick = false;
    },
    popMenu() {
      this.userClick = !this.userClick;
    },
    documentMenu() {
      this.documentShow = !this.documentShow;
    },
    documentMenuDoc() {
      this.documentShowDoc = !this.documentShowDoc;
    },
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (e.target.className != "drop-menu") {
        this.documentShow = false;
      }
    });
    document.addEventListener("click", (e) => {
      if (e.target.className != "drop-menu-doc") {
        this.documentShowDoc = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 780px) {
  .nav-bg-box {
    display: none;
  }
}

.nav-bg-box {
  width: 100%;
  height: 56px;
  background-color: #fff;
  z-index: 999;
}
.nav-bg {
  width: 100%;
  height: 56px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  .nav-bar {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    position: relative;
    .nav-img {
      position: absolute;
      top: 12px;
      left: 9px;
      cursor: pointer;
      img {
        vertical-align: bottom;
        width: 115.4px;
        height: auto;
      }
      .title {
        font-family: MagistralC-Bold, Helvetica Neue, Helvetica, Arial,
          sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        display: inline-block;
        height: 40px;
        line-height: 52px;
        text-align: center;
        letter-spacing: 0.08em;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
  .menu-arrow-down {
    position: absolute;
    right: 16px;
    z-index: 9;
  }
  .nav-title {
    position: absolute;
    left: 0;
    top: 56px;
    background-color: #fff;
    color: #000000;
    line-height: 40px;
    width: 100%;
    height: calc(100vh - 48px);
    z-index: 99999;
    img {
      width: 16px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
      margin-top: -4px;
    }
  }
  .switch-menu {
    overflow: hidden;
    animation: menufirst 0.3s ease-in-out both;
    -webkit-animation: menufirst 0.3s ease-in-out both;
    animation-fill-mode: forwards;
    li {
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      list-style: none;
      text-align: left;
      cursor: pointer;
      text-align: center;
      line-height: 55px;
      width: 200px;
      margin: 0 auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
  .drop-menu {
  }
  .drop-list {
    width: 220px;
    overflow: hidden;
    ul {
      animation: myfirst 0.3s;
      -webkit-animation: myfirst 0.3s;
      animation-fill-mode: forwards;
      position: relative;
    }
    li {
      margin-left: 0;
      text-align: center;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      list-style: none;
      border-bottom: 0;
      margin-top: 32px;
    }
    li:first-child {
      margin-top: 15px;
    }
    li:last-child {
      margin-bottom: 23px;
    }
    li:hover {
      background-color: #fafafa;
      color: rgba(26, 97, 234, 1);
      a {
        color: rgba(26, 97, 234, 1) !important;
      }
    }
    li:active {
      background-color: #fafafa;
      color: #000000;
      a {
        color: #000000 !important;
      }
    }
  }
  .drop-list-doc {
    width: 220px;
    overflow: hidden;
    ul {
      animation: myfirstdoc 0.3s;
      -webkit-animation: myfirstdoc 0.3s;
      animation-fill-mode: forwards;
      position: relative;
    }
    li {
      margin-left: 0;
      text-align: center;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      list-style: none;
      border-bottom: 0;
      margin-top: 32px;
    }
    li:first-child {
      margin-top: 15px;
    }
    li:last-child {
      margin-bottom: 23px;
    }
    li:hover {
      background-color: #fafafa;
      color: rgba(26, 97, 234, 1);
      a {
        color: rgba(26, 97, 234, 1) !important;
      }
    }
    li:active {
      background-color: #fafafa;
      color: #000000;
      a {
        color: #000000 !important;
      }
    }
  }
}
@-webkit-keyframes myfirst {
  0% {
    height: 0px;
  }
  100% {
    height: 192px;
  }
}

@-webkit-keyframes myfirstdoc {
  0% {
    height: 0px;
  }
  100% {
    height: 48px;
  }
}
@-webkit-keyframes menufirst {
  0% {
    height: 0px;
  }
  100% {
    height: 492px;
  }
}
</style>